:root {
  --bc-font-stack: 'Montserrat', sans-serif;
  --bc-fontSize-xxs: 10px;
  --bc-fontSize-xs: 12px;
  --bc-fontSize-sm: 16px;
  --bc-fontSize-md: 18px;
  --bc-fontSize-lg: 22px;
  --bc-fontSize-xl: 26px;
  --bc-fontSize-xxl: 32px;
  --bc-fontWeight-light: 100;
  --bc-fontWeight-regular: 300;
  --bc-fontWeight-medium: 500;
  --bc-fontWeight-bold: 700;
  --bc-lineHeight-xs: 18px;
  --bc-lineHeight-sm: 22px;
  --bc-lineHeight-md: 28px;
  --bc-radius: 10px;
  --bc-links: #6A6B83;
  --bc-primary-color: #065A60;
  --bc-primary-color-90: rgba(0, 100, 102, 0.9);
  --bc-primary-lighter: #95D5B2;
  --bc-primary-darker: #065A60;
  --bc-secondary-color: #6A6B83;
  --bc-secondary-light: #DBCBD8;
  --bc-secondary-light-hover: #191919;
  --bc-light-color: #ccdbd5;
  --bc-danger-color: #b52e2e;
  --bc-success-color: #2eb582;
  --bc-controls-color: #fff;
}
