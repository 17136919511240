@import './constants.scss';

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blur {
  -webkit-filter: blur(3px);
  -o-filter: blur(3px);
  filter: blur(3px);
}
/* Accounts styles ======================================================*/
.logo {
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}
.bc-logo {
  background-image: url('/assets/logo/wlogo.svg');
  background-repeat: no-repeat;
  width: 27px;
  height: 60px;
  display: inline-block;
}

/*guide styles ==================================================*/

.guide-con {
  height: 530px;
  width: 60%;
  margin-top: 80px;
  .form-group button {
    margin: 3px;
  }
}

.guide {
  margin-top: 0px;
  font-size: 13px;
  border-right: 1px solid #9b9b9b;
  position: relative;
  float: right;
  width: 140px;
  margin-right: 25px;
}
.guide li {
  margin: 50px 0 50px;
}

.guide-2 {
  margin-top: 100px;
}

.guide-2 h2 {
  margin-bottom: 0px;
}

.guide .number {
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #9b9b9b;
  border-radius: 12.5px;
  -moz-border-radius: 12.5px;
  -webkit-border-radius: 12.5px;
  color: #9b9b9b;
  display: inline-block;
  font-weight: bold;
  line-height: 25px;
  height: 25px;
  text-align: center;
  width: 25px;
  position: absolute;
  right: -1em;
  margin-top: -12px;
  display: inline-block;
}

.guide .guide-icon {
  border: none;
  background-color: #fff;
  text-align: center;
  font-size: 25px;
  border-radius: 0em;
  -moz-border-radius: 0em;
  -webkit-border-radius: 0em;
}

.guide .guide-icon i {
  position: absolute;
  left: -13px;
}

.guide ul li {
  list-style: none;
  margin-bottom: 20px;
}

/*profile styles ==================================================*/

.profile-nav ol,
ul {
  list-style: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.profile-nav i {
  margin-bottom: -6px;
  margin-right: 5px;
}

.profile-nav li {
  display: inline-block;
  cursor: pointer;
}

.profile-nav a {
  position: relative;
  text-decoration: none;
  transition: color 0.1s, background-color 0.1s;
  display: block;
  padding: 10px;
  margin: 0 10px;
  transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
  color: #4d4d4d;
  cursor: pointer;
}

.profile-nav a::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: var(--bc-links);
  transform-origin: right top;
  transform: scale(0, 1);
  transition: color 0.1s, transform 0.2s ease-out;
}

a:hover::before,
a:focus::before,
a.active::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.profile-nav a:hover,
a:focus,
a:active,
a.active {
  color: var(--bc-links);
  text-decoration: none;
}

.masterform {
  margin-top: 70px;
}

.company-btn {
  margin-left: 20px;
}

.btnProf {
  float: right;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .profile-nav a {
    margin-right: -5px;
    margin-bottom: -2px;
    padding-bottom: 6px;
    font-size: 12px;
  }
  .company-btn {
    margin-right: 15px;
  }
  a:hover::before,
  a:focus::before,
  a.active::before {
    transform-origin: left top;
    transform: scale(0.8, 0.8);
  }
}

/*listing pages styles ==================================================*/
.listing .form-group {
  margin-top: 0px;
  margin-bottom: 5px;
}
.listing .dropdown-content,
.listing .dropdown-content2 {
  right: 0px;
  top: 45px;
}
.relative {
  display: block;
}

/*addTextbox styles styles ==================================================*/
.addTextBox .dropdown {
  display: flex;
  margin-right: 5px;
  height: 35px;
  max-width: 350px;
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
}

.addTextBox .primary-btn {
  height: 35px;
  margin-top: 10px;
}
/*company Profile styles ==================================================*/
.inline-form-group > span {
  margin-right: 20px;
}
.company-header {
  margin-top: -20px;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
}

table .dropdown button {
  margin-left: 0px;
}

table .dropdown-content {
  margin-left: -7px;
  margin-top: -3px;
}

td,
th {
  padding: 12px 30px;
  border-bottom: 1px solid #e9e8e8;
}

th a {
  margin-left: 20px;
}

tr:nth-child {
  background-color: #dddddd;
}

.green-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.action-icon {
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
}

.pending {
  color: rgb(240, 165, 105);
}
.inactive {
  color: red;
}

.userGroup th:first-of-type {
  width: 15%;
}

.userGroup th:last-of-type {
  width: 10%;
}
.table-style .excel-icon {
  float: right;
}
.table-style tr th {
  cursor: pointer;
}

.forTable {
  display: none;
}

.iconClass::after {
  content: '•';
  margin-left: 4px;
}

.tableSearch {
  margin-top: 20px;
  left: 150px;
}

/*checkbox Component styles ==================================================*/
.toast-style {
  width: 312px;
  height: 65px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 5px solid #662d91;
  background: #f3f3f3;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4d4d4d;
}
.Toastify__close-button {
  color: #4d4d4d;
  margin-top: 0px;
}

/*Libraries overridings ===============================================*/
.tippy-tooltip {
  padding: 10px 8px 8px !important;
  font-size: 15px;
}
.Toastify__toast {
  border-radius: 25px 10px !important;
}
.rt-DataListLabel::before, .rt-DataListValue::before {
  content: '' !important;
}