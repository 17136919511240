.bc-logout {
    background-image: url("/assets/icons/white/logout.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-logout-hov,
.bc-logout-hov:hover,
.bc-logout-active {
    background-image: url("/assets/icons/colored/logout.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-companies {
    background-image: url("/assets/icons/white/companies.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-companies-hov,
.bc-companies-hov:hover,
.bc-companies-active {
    background-image: url("/assets/icons/colored/companies.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-add {
    background-image: url("/assets/icons/white/add.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-add-hov,
.bc-add-hov:hover,
.bc-add-active {
    background-image: url("/assets/icons/colored/add.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-add-filled {
    background-image: url("/assets/icons/white/add-filled.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-add-filled-hov,
.bc-add-filled-hov:hover,
.bc-add-filled-active {
    background-image: url("/assets/icons/colored/add-filled.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-help {
    background-image: url("/assets/icons/white/help.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-help-hov,
.bc-help-hov:hover,
.bc-help-active {
    background-image: url("/assets/icons/colored/help.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-settings {
    background-image: url("/assets/icons/white/setting.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-settings-hov,
.bc-settings-hov:hover,
.bc-settings-active {
    background-image: url("/assets/icons/colored/setting.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-billing {
    background-image: url("/assets/icons/white/billing.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-billing-hov,
.bc-billing-hov:hover,
.bc-billing-active {
    background-image: url("/assets/icons/colored/billing.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-user {
    background-image: url("/assets/icons/white/user.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-user-hov,
.bc-user-hov:hover,
.bc-user-active {
    background-image: url("/assets/icons/colored/user.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-pages {
    background-image: url("/assets/icons/white/pages.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-pages-hov,
.bc-pages-hov:hover,
.bc-pages-active {
    background-image: url("/assets/icons/colored/pages.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-apps {
    background-image: url("/assets/icons/white/apps.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-apps-hov,
.bc-apps-hov:hover,
.bc-apps-active {
    background-image: url("/assets/icons/colored/apps.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.bc-home {
    background-image: url("/assets/icons/white/home.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
.icon-con:hover .bc-home-hov,
.bc-home-hov:hover,
.bc-home-active {
    background-image: url("/assets/icons/colored/home.svg");
    height: 30px;
    width: 30px;
    display: inline-block;
}
