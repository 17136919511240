@import './constants.scss';
/*Colors defaults ==================================================*/
.primary-border {
  border-color: var(--bc-primary-color);
}
.primary-fill {
  fill: var(--bc-primary-color);
}
.primary-darkest-backgroundcolor {
  background-color: var(--bc-primary-darker);
}
.danger-color {
  color: var(--bc-danger-color);
}
.primary-color {
  color: var(--bc-primary-color);
}
.line-height-small {
  line-height: var(--bc-lineHeight-sm);
}
.secondary-light {
  color: var(--bc-secondary-light);
}
/*Fonts defaults ==================================================*/
.fontWeight-light {
  font-weight: var(--bc-fontWeight-light);
}
.fontWeight-regular {
  font-weight: var(--bc-fontWeight-regular);
}
.fontSize-xs {
  font-size: var(--bc-fontSize-xs);
}
.fontSize-sm {
  font-size: var(--bc-fontSize-sm);
}
/*Elements defaults ==================================================*/
body {
  font-family: var(--bc-font-stack);
  color: var(--bc-secondary-color);
  font-size: var(--bc-fontSize-xs);
  font-weight: var(--bc-fontWeight-bold);
}
p {
  font-weight: var(--bc-fontWeight-medium);
  font-size: var(--bc-fontSize-sm);
  line-height: var(--bc-lineHeight-sm);
}
a {
  color: var(--bc-primary-color);
  text-decoration: none;
  font-size: var(--bc-fontSize-sm);
  font-weight: var(--bc-fontWeight-medium);
  line-height: 2.4;
}
link h1 {
  font-size: 36px;
  color: var(--bc-primary-color);
  font-weight: var(--bc-fontWeight-bold);
  letter-spacing: 0;
}
h2 {
  font-size: var(--bc-fontSize-xl);
  margin-bottom: 25px;
  font-weight: var(--bc-fontWeight-medium);
}
h3 {
  font-size: var(--bc-fontSize-md);
  font-weight: var(--bc-fontWeight-medium);
  line-height: var(--bc-lineHeight-md);
}
h4 {
  color: var(--bc-primary-lighter);
}
h5 {
  font-weight: 300;
  font-size: 25px;
  margin-bottom: 25px;
  margin-top: -20px;
}
@media (max-width: 767px) {
  body {
    font-size: 17px;
  }
  .small {
    font-size: 13px;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    color: var(--bc-primary-lighter);
  }
}
hr {
  /* Set the hr color */
  color: var(--bc-controls-color); /* old IE */
  background-color: var(--bc-controls-color);
}
/*Forms styles rules ==================================================*/
.form-group {
  font-weight: var(--bc-fontWeight-regular);
}
.form-group label {
  font-weight: 500;
  font-size: var(--bc-fontSize-xs);
}
input {
  background-color: var(--bc-controls-color);
  border: 1px solid var(--bc-primary-color);
  border-radius: var(--bc-radius);
  color: var(--bc-secondary-color);
  font-size: var(--bc-fontSize-xs);
  font-weight: var(--bc-fontWeight-regular);
}
input:read-only,
.editor:read-only {
  color: #999;
}
input:focus,
textarea:focus,
.editor:focus {
  outline: none;
}
input:-webkit-autofill,
.editor:-webkit-autofill {
  background-color: var(--bc-controls-color) !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bc-secondary-light);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--bc-secondary-light);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--bc-secondary-light);
}
input.danger {
  border-color: var(--bc-danger-color);
}
input.success {
  border-color: var(--bc-success-color);
}
input.info {
  border-color: var(--bc-primary-lighter);
}
.danger {
  color: var(--bc-danger-color);
}
.grey {
  color: var(--bc-secondary-light);
}
.info {
  color: var(--bc-primary-lighter);
}
.success {
  color: var(--bc-success-color);
}
button,
.conversationVote > button {
  color: #fff;
  font-size: var(--bc-fontSize-xs);
  font-weight: var(--bc-fontWeight-regular);
  margin: 10px 0;
}
button:focus {
  outline: none;
}
.primary-btn,
.rounded-btn {
  background-color: var(--bc-primary-color);
  color: #fff;
  border: none;
  cursor: pointer;
}
.rounded-btn {
  border-radius: 100px !important;
}
.sec-btn {
  border: 1px solid var(--bc-primary-color);
  background: none;
  color: var(--bc-primary-color);
  cursor: pointer;
}
.sec-btn:hover {
  border: 1px solid var(--bc-primary-darker);
  color: var(--bc-primary-darker);
}
.primary-btn:active,
.rounded-btn:active {
  background-color: var(--bc-primary-darker);
}
.primary-btn:disabled,
.primary-btn[disabled],
.primary-btn:disabled:hover,
.rounded-btn:disabled,
.rounded-btn[disabled],
.rounded-btn:disabled:hover {
  background: var(--bc-secondary-color);
  color: #fff;
  opacity: 0.5;
  cursor: auto;
}
.primary-btn:hover,
.rounded-btn:hover {
  background: none;
  background-color: var(--bc-primary-color-90);
}

.sec-btn:disabled,
.sec-btn[disabled],
.sec-btn:disabled:hover {
  border: 2px solid var(--bc-secondary-light);
  background: none;
  color: var(--bc-secondary-light);
  cursor: auto;
}
.xsm-btn,
.sm-btn,
.md-btn,
.lg-btn,
.xl-btn,
.xxl-btn,
.long-btn > button {
  border-radius: var(--bc-radius);
  text-transform: uppercase;
}
.btn-loading {
  background-image: url('/assets/loading.gif');
  background-size: contain;
}
.btn-icon {
  height: 35px;
  width: 35px;
}
.has-error {
  border: 1px var(--bc-danger-color) solid;
}
.alert-danger {
  color: var(--bc-danger-color);
}
.alert-danger p,
.alert-warning p,
.alert-success p {
  line-height: 1;
  font-size: 14px;
}
.alert-warning {
  color: var(--bc-primary-lighter);
}
.alert-success {
  color: var(--bc-success-color);
}
/*Auto Suggestions component styles ====================================*/
.react-autosuggest__suggestions-list {
  background-color: #f9f9f9;
  width: 200px;
  border-radius: var(--bc-radius);
}
.react-autosuggest__suggestions-list li {
  color: var(--bc-secondary-color);
  font-size: 13px;
  width: 200px;
}
.react-autosuggest__suggestions-list li:first-of-type {
  border-top-right-radius: var(--bc-radius);
  border-top-left-radius: var(--bc-radius);
}
.react-autosuggest__suggestions-list li:last-child {
  border-bottom-right-radius: var(--bc-radius);
  border-bottom-left-radius: var(--bc-radius);
}
.react-autosuggest__suggestions-list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
/*Tag rules ==================================================*/
.tag .dropdown-content2 a {
  color: var(--bc-secondary-color);
  font-size: 14px;
}
.tag .dropdown-content {
  background-color: #f9f9f9;
}
.tag .dropdown-content2 {
  background-color: #f9f9f9;
  max-height: 300px;
  overflow: auto;
}

/*Navigations rules ==================================================*/
.profile-nav {
  border-bottom: 1px solid var(--bc-controls-color);
}
/*Other pages and components ========================================*/
.guide .guide-icon {
  color: var(--bc-success-color);
}
.green-circle {
  background-color: var(--bc-success-color);
}
div,
span,
input {
  text-size-adjust: auto;
}
.orange {
  color: var(--bc-primary-color);
}

/*Placeholders styles*/
::-webkit-input-placeholder {
  /* Edge */
  color: var(--bc-secondary-light);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: var(--bc-secondary-light);
}
::placeholder {
  color: var(--bc-secondary-light);
}
