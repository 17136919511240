@import "./constants.scss";
/*Containers styles ==================================================*/
.fill-con {
  position: fixed;
  width: 100%;
  height: 100%;
}
.fill {
  min-height: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  padding: 40px 30px;
  overflow: auto;
}
.fill-90 {
  height: 90%;
}
.fill-10 {
  height: 10%;
}
.form-container {
  width: 600px;
  max-width: 100%;
}
.form-container-lg {
  width: 994px;
  max-width: 100%;
}
.centered-con {
  margin-left: auto;
  margin-right: auto;
}
/*Quick styles rules ==================================================*/
.text-align {
  text-align: center;
}
.margin-center {
  margin: 0 auto;
}
.float-left {
  float: left;
}

.float-right {
  float: right;
  margin-left: 6px;
}

.marginTop2 {
  margin-bottom: -5px;
}
.marginTop,
.marginTop80 {
  margin-top: 80px;
}
.marginTop30 {
  margin-top: 30px;
}
.mobile-padding {
  width: 50%;
}
.col-xs-12.white-box {
  padding: 62px;
  margin-bottom: 40px;
  display: inline-block;
  height: auto;
  margin: auto;
}
@media (max-width: 1280px) {
  .mobile-padding {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .mobile-padding {
    width: 95%;
  }
}
.icon-width {
  width: 30px;
}
/*Elements defaults ==================================================*/
hr {
  margin: 16px 0 20px;
  border: none;
  height: 1px;
  color: #f0f0f0;
}
.hr2 {
  margin: 20px 0px 20px;
  height: 1px;
  color: #f0f0f0;
}
/*Forms styles rules ==================================================*/
.form-group {
  margin: 4px 0 8px;
}
.form-group .form-group-col {
  padding: 4px;
}
.form-group .form-group-col button {
  margin: 0px;
}
.form-group .form-group-col-first {
  padding-left: 0;
}
.form-group .form-group-col:last-child {
  padding-right: 0;
}
.inline-form-group {
  display: inline-block;
}
.form-group label {
  margin-bottom: 4px;
  margin: 20px 0px 5px;
  width: 100%;
}
.form-group button {
  margin: 0;
}
input {
  height: 40px;
  padding: 6px 15px;
  margin: 10px 0;
  width: 100%;
  min-width: 220px;
}
.long-btn {
  width: 148px;
  height: 40px;
}
.xsm-btn {
  width: 50px;
  height: 40px;
}
.sm-btn {
  width: 100px;
  height: 40px;
}
.md-btn {
  width: 148px;
  height: 40px;
}
.lg-btn {
  width: 220px;
  height: 40px;
}
.xl-btn {
  width: 327px;
  height: 40px;
}
.xxl-btn {
  width: 400px;
  height: 40px;
}
.full-width-btn {
  width: 100%;
}
.rounded-btn {
  height: 30px;
}
.rounded-btn.lg-btn, .rounded-btn.xl-btn, .rounded-btn.xxl-btn {
  height: 35px;
}
.btn-loading {
  width: 50px;
  height: 40px;
  display: inline-block;
  position: absolute;
  right: 10%;
  top: 10px;
}
@media (max-width: 767px) {
  input {
    width: 100%;
  }
}
.form-group.full-width-inputs input {
  width: 100%;
}
input[type="checkbox"] {
  width: 30px;
  height: 30px;
}
/*Bootstrap defaults ==================================================*/
.col-xs-12 {
  padding: 0px 20px;
}
.xs-container {
  width: 100%;
}
.md-width {
  width: 80%;
}
@media (min-width: 768px) {
}
@media (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
  .visible-md {
    display: block;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .xs-width {
    width: 100%;
  }
  .xs-container {
    margin: auto;
    width: auto;
  }
}

/*Auto Suggestions component styles ====================================*/
.react-tagsinput-ta33 {
  position: relative;
}
.react-autosuggest__suggestions-container {
  position: absolute;
  top: 35px;
  z-index: 999;
}
.react-autosuggest__suggestions-list li {
  width: 133px;
  padding: 10px;
  display: block;
  cursor: pointer;
  height: 40px;
}
/*Navigations rules ==================================================*/
.profile-nav {
  margin-bottom: 15px;
}
/* Input-range  ==================================================*/
.input-range {
  margin: 12px 0px 25px 0;
  width: 96%;
}
.input-range__track--active,
.input-range__slider {
  background: #f5a623;
  border-color: #f5a623;
  color: #f5a623;
}
.input-range {
  margin: 12px 0px 25px 0;
}
.input-range__track .input-range__track--background .input-range__track {
  border-radius: none;
  min-height: 6px;
}
.input-range__slider-container .input-range__slider {
  background-color: #fff;
  border: 2px solid #f5a623;
}
/*  Key value  ==================================================*/
.keyValueItem {
  width: 38%;
  float: left;
  padding: 0 4px;
  margin: 0;
}
.keyValueCommand {
  width: 24%;
  float: left;
  padding: 0px 4px 0;
  margin: 0;
}
/*chatbots in company Profile styles ==================================================*/
.tag {
  width: 230px;
  height: 35px;
  border-radius: 12px;
  background-color: #f0f0f0;
  font-size: smaller;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  position: relative;
  margin-bottom: 10px;
}
.tag > span {
  float: left;
}
.dropbtnTagCon {
  width: 40%;
  display: inline-block;
  top: 0;
}
.dropbtnTag {
  width: 100%;
  border: none;
  cursor: pointer;
  background: none;
  position: relative;
  margin: 0;
}
.dropbtnTag span {
  display: block;
  color: #4d4d4d;
  margin-top: -5px;
  margin-left: -10px;
  font-size: 12px;
}
.dropbtnTag span i {
  margin-bottom: -5px;
  margin-left: 5px;
}
.dropbtnTag > i {
  color: #4d4d4d;
  right: 0;
  border-right: 1px solid #4d4d4d;
  position: absolute;
  top: 10px;
  padding-right: 5px;
}
.tagTextbox {
  width: 60%;
  margin-top: 7px;
  position: relative;
  display: inline-block;
}
.dropdown-content i {
  font-size: 14px;
  display: block;
  cursor: pointer;
  padding: 0px 5px;
  text-align: center;
}
.dropdown-content .icons i {
  display: inline-block;
  margin-bottom: -5px;
  margin-left: 0;
  margin-right: 5px;
}
.tagContent {
  font-size: var(--bc-fontSize-xs);
  width: 60%;
  position: relative;
  display: inline-block;
  padding: 3px 12px;
}
.tagTextbox.full-width,
.tagContent.full-width {
  width: 100%;
}
.tag .dropdown-content {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 35px;
}
.tag .tagTextbox .dropdown-content a {
  width: 100%;
}

.addTag {
  background: none;
  margin-top: -10px;
}

.tagIcons i:first-of-type {
  padding: 4px 4px;
  border: none;
  float: left;
  margin-top: 3px;
}

.tagIcons i:last-of-type {
  margin-right: -30px;
}

.tagContent .fa-times {
  cursor: pointer;
  top: 10px;
  position: absolute;
  right: 10px;
}

.chatbots .userSpan,
.chatbots .add {
  padding: 12px 40px;
}
.zindex {
  z-index: 9999;
}
.con-center {
  text-align: center;
  margin: auto;
}
.small-width {
  width: 100px;
  display: inline-block;
}
.flex {
  display: flex;
}
.width-sm {
  width: 660px;
}
.display-block {
  display: block;
}
.textArea {
  height: 72px;
  vertical-align: 0px;
}
.marginRight {
  margin-right: 17px;
}
.floatLeft {
  float: left;
}
.hide {
  display: none;
}
/*input-tag in styles ==================================================*/
.react-tagsinput-ta33 {
  background: #f0f0f0;
  padding: 2px 15px;
  padding-top: 4px;
  border-radius: 10px;
  margin-top: 10px;
}
.react-tagsinput-ta33 .react-tagsinput-tag {
  background-color: #fff;
  border-radius: 10px;
  color: #424242;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 3px 10px;
  border: none;
}
.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: #9b9b9b;
}
.react-tagsinput-ta33 .react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}
.password-icon {
  width: 22px;
  height: 22px;
  margin-top: 12px;
  margin-left: -50px;
  position: absolute;
  cursor: pointer;
  background-image: url("/assets/icons/line/eye_line.svg");
  display: inline-block;
}
.icon-con:hover .password-icon,
.password-icon:hover {
  background-image: url("/assets/icons/color/eye_color.svg");
}
/*Button styles ==================================================*/
.center {
  text-align: center;
  margin: 0 auto;
}
.hidden {
  display: none;
}
.textCenter {
  text-align: center;
}
/*DatePicker styles ==================================================*/
.datepicker {
  .react-datepicker {
    border: none;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  }
  .react-datepicker__triangle {
    display: none;
  }

  .datepicker .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #4d4d4d;
    width: 1.96rem;
    line-height: 1.96rem;
  }
  .react-datepicker__header {
    border: none;
  }
  .react-datepicker__triangle {
    border: none;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__day--selected:focus,
  .react-datepicker__day--in-selecting-range:focus,
  .react-datepicker__day--in-range:focus,
  .react-datepicker__month-text--selected:focus,
  .react-datepicker__month-text--in-selecting-range:focus,
  .react-datepicker__month-text--in-range:focus {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #4d4d4d;
    margin: 0px 6.7px;
    color: #fff;
  }
  .datepicker .react-datepicker__day-name,
  .react-datepicker__day,
  .datepicker .react-datepicker__time-name {
    margin: 0px 5.9px;
  }
  .datepicker .datepicker .react-datepicker__day-name:hover,
  .datepicker .react-datepicker__day:hover,
  .datepicker .react-datepicker__time-name:hover {
    width: 30px;
    height: 30px;
    margin: 0px 6.7px;
    border-radius: 50%;
    background-color: #4d4d4d;
    color: #fff;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #4d4d4d;
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    margin: 7px 0px;
  }
}
/* Filters panels ==================================================*/
.search-filer-panel{
  float: right;
  margin-top: -10px;
  position: relative;
  width: 400px;
}
