.checkbox-style {
  padding-left: 40px;
  padding-top: 8px;
  .checkmark {
    position: absolute;
    padding: 4px;
    top: 2px;
    left: 0;
    height: 30px;
    width: 30px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px 10px;
    cursor: pointer;
    z-index: 1;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  input ~ .checkmark {
    svg {
      display: none;
    }
  }
  input:checked ~ .checkmark {
    svg {
      display: block;
    }
  }
}
.checkbox-style.small {
  .checkmark {
    height: 20px;
    width: 20px;
    border-width: 2px;
    border-radius: 2px 5px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}
